import PbCheckBox from "@/components/input/pbCheckbox/pbCheckbox";
import PbInputControlled from "@/components/input/pbInputControlled/pbInputControlled";
import ModalInputWrapper from "@/components/util/modalInputWrapper";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import { globalConfig } from "@/services/globalConfig/globalConfigService";
import { getStrapiURLClientSide } from "@/utils/api";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

interface CmsPageSeoSettingsProps {
  data: any;
  onChange: (name: string, value: any) => void;
}

export default function CmsPageSeoSettings(
  props: Readonly<CmsPageSeoSettingsProps>
) {
  const tCms = useCmsTranslation();

  const router = useRouter();

  const [seoTitle, setSeoTitle] = useState<string>("");
  const [seoDescription, setSeoDescription] = useState<string>("");
  const [seoRobotsFlag, setSeoRobotsFlag] = useState<boolean>(true);
  const [seoCanonical, setSeoCanonical] = useState<string>(
    props.data.canonicalLinkUrl ?? ""
  );

  const [seoMetaTag, setSeoMetaTag] = useState<string>(
    '<meta name="robots" content="index, follow" />'
  );

  const seoLines = seoMetaTag?.split(/\r?\n|\r|\n/g);

  const sanitizeInput = (input: string) => {
    return input.replaceAll('"', "");
  };

  const generateSeoMetaTag = (
    seoTitleInput: string,
    seoDescriptionInput: string,
    seoRobotsInput: boolean,
    seoCanonicalInput: string
  ) => {
    const generateMetaTag = (name: string, content: string) =>
      content ? `<meta name="${name}" content="${content}" />` : "";
    const generatePropertyMetaTag = (property: string, content: string) =>
      content ? `<meta property="${property}" content="${content}" />` : "";

    const seoOgImage = (propertyName: string) => {
      const existingOgImage = seoLines?.find((line) =>
        line.startsWith(`<meta property="${propertyName}"`)
      );
      return (
        existingOgImage ||
        (globalConfig?.seo.fallbackOpenGraphImage &&
          generatePropertyMetaTag(
            propertyName,
            getStrapiURLClientSide(globalConfig.seo.fallbackOpenGraphImage.url)
          )) ||
        ""
      );
    };

    const seoMetaTagString = [
      generateMetaTag("title", seoTitleInput),
      generateMetaTag("description", seoDescriptionInput),
      generateMetaTag(
        "robots",
        seoRobotsInput ? "index, follow" : "noindex, follow"
      ),
      generatePropertyMetaTag("og:title", seoTitleInput),
      generatePropertyMetaTag("og:description", seoDescriptionInput),
      generatePropertyMetaTag("og:url", seoCanonicalInput),
      generatePropertyMetaTag("og:locale", router.locale ?? ""),
      generatePropertyMetaTag("og:type", "website"),
      seoOgImage("og:image"),
      generatePropertyMetaTag(
        "twitter:card",
        globalConfig?.seo?.twitterCardContent ?? ""
      ),
      generatePropertyMetaTag(
        "twitter:site",
        globalConfig?.seo?.twitterSiteContent ?? ""
      ),
      generatePropertyMetaTag("twitter:description", seoDescriptionInput),
      generatePropertyMetaTag("twitter:title", seoTitleInput),
      seoOgImage("twitter:image"),
    ]
      .filter((metaTag) => metaTag.length > 0)
      .join("\n");
    setSeoMetaTag(seoMetaTagString);
    props.onChange("seoSettings", seoMetaTagString);
  };

  useEffect(() => {
    const parser = new DOMParser();
    const document = parser
      .parseFromString(props.data.seoSettings, "text/html")
      .getElementsByTagName("meta");
    for (const tag of document) {
      switch (tag.name) {
        case "title":
          setSeoTitle(tag.content);
          break;
        case "description":
          setSeoDescription(tag.content);
          break;
        case "robots":
          setSeoRobotsFlag(!tag.content.includes("noindex"));
          break;
        case "canonical":
          setSeoCanonical(tag.content);
          break;
        default:
          break;
      }
    }
    if (props.data.seoSettings) {
      setSeoMetaTag(props.data.seoSettings);
    }
  }, [props, seoMetaTag, props.data.seoSettings]);

  return (
    <div className="cms-modal-input-wrapper-container">
      <ModalInputWrapper
        label={tCms("seo-advancedSeoSettings")}
        description={tCms("seo-advancedSeoSettingsDescription")}
        fullWidth
      >
        <PbInputControlled
          withCmsStyle
          label={""}
          minRows={2}
          maxLength={10000}
          multiline
          fullWidth
          value={props.data.seoSettings ?? seoMetaTag}
          placeholder={tCms("seoExampleWithoutPageName")}
          onChange={(e) => {
            props.onChange("seoSettings", e.target.value);
          }}
        />
      </ModalInputWrapper>
      <ModalInputWrapper
        label={tCms("title")}
        description={tCms("page-seoTitleDescription")}
      >
        <PbInputControlled
          withCmsStyle
          label={""}
          fullWidth
          multiline
          minRows={2}
          value={seoTitle}
          onChange={(e) => {
            const title = sanitizeInput(e.target.value);
            generateSeoMetaTag(
              sanitizeInput(title),
              seoDescription,
              seoRobotsFlag,
              seoCanonical
            );
            setSeoTitle(title);
          }}
        />
      </ModalInputWrapper>
      <ModalInputWrapper
        label={tCms("page-seoTitleAsPageTitle")}
        description={tCms("page-seoTitleAsPageTitleDescription")}
      >
        <PbCheckBox
          isSlider
          value={props.data.isSeoTitlePageTitle}
          onChange={(e) => {
            props.onChange("isSeoTitlePageTitle", e.target.checked);
          }}
          fullHeight
        />
      </ModalInputWrapper>
      <ModalInputWrapper
        label={tCms("description")}
        description={tCms("page-seoDescriptionDescription")}
      >
        <PbInputControlled
          withCmsStyle
          label={""}
          fullWidth
          multiline
          minRows={2}
          value={seoDescription}
          onChange={(e) => {
            const description = sanitizeInput(e.target.value);
            generateSeoMetaTag(
              seoTitle,
              description,
              seoRobotsFlag,
              seoCanonical
            );
            setSeoDescription(description);
          }}
        />
      </ModalInputWrapper>
      <ModalInputWrapper
        label={tCms("indexing")}
        description={tCms("page-indexingDescription")}
      >
        <PbCheckBox
          isSlider
          value={seoRobotsFlag}
          onChange={(e) => {
            generateSeoMetaTag(
              seoTitle,
              seoDescription,
              e.target.checked,
              seoCanonical
            );
            setSeoRobotsFlag(e.target.checked);
          }}
          fullHeight
        />
      </ModalInputWrapper>
      <ModalInputWrapper
        label={tCms("page-canonicalLink")}
        description={tCms("page-canonicalLinkDescription")}
      >
        <PbInputControlled
          withCmsStyle
          label={""}
          fullWidth
          value={props.data.canonicalLinkUrl}
          onChange={(e) => {
            const canonicalLink = sanitizeInput(e.target.value);
            generateSeoMetaTag(
              seoTitle,
              seoDescription,
              seoRobotsFlag,
              canonicalLink
            );
            setSeoCanonical(canonicalLink);
            props.onChange("canonicalLink", canonicalLink);
          }}
        />
      </ModalInputWrapper>
    </div>
  );
}
