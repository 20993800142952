import { useRouter } from "next/router";
import { useAppSelector } from "@/store/store";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import { convertTimeStringToDate } from "@/utils/util";
import PbDateTime from "@/components/input/pbDateTime/pbDateTime";
import ModalInputWrapper from "@/components/util/modalInputWrapper";
import { isLocaleDefaultLocale } from "@/utils/localizationUtil";
import PbCheckBox from "@/components/input/pbCheckbox/pbCheckbox";
import PbDropdown from "@/components/input/pbDropdown/pbDropdown";

interface CmsAttributeTimeAndSchedulingProps {
  name: string;
  data: any;
  attributes: any;
  onChange: (name: string, value: any) => void;
}

export default function CmsAttributeTimeAndScheduling(
  props: Readonly<CmsAttributeTimeAndSchedulingProps>
) {
  const router = useRouter();
  const editView = useAppSelector((state) => state.cmsGeneral.editView);
  const tCms = useCmsTranslation();

  const parseDateAndTimeToDateTime = (
    date: string | undefined,
    time: string | undefined
  ) => {
    const baseDate = date ? new Date(date) : new Date();
    return time ? convertTimeStringToDate(time, baseDate) : baseDate;
  };

  const getEndStartDate = (
    endDate: string | undefined,
    endTime: string | undefined
  ) => {
    if (!endDate && !endTime) {
      return undefined;
    }
    return parseDateAndTimeToDateTime(endDate, endTime);
  };

  const isNonLocalizedDisabled = (attribute: any) => {
    if (!attribute) {
      return false;
    }
    return (
      editView &&
      nonLocalized(attribute) &&
      !isLocaleDefaultLocale(router.locale!)
    );
  };

  const nonLocalized = (attribute: any) => {
    return attribute["pluginOptions"]?.["i18n"]?.["localized"];
  };

  const repetitionIntervalOptions = (): { name: string; value: string }[] => {
    const options: string[] =
      props.attributes["repetitionInterval"]?.enum ?? [];
    return options.map((option: string) => ({
      name: tCms(option),
      value: option,
    }));
  };

  const timeAndSchedulingData = props.data[props.name] ?? {};

  return (
    <>
      <ModalInputWrapper
        label={tCms("event-startTime")}
        description={tCms("event-startDateTimeDescription")}
        nonLocalized={nonLocalized(props.attributes["startDate"])}
      >
        <PbDateTime
          fullWidth
          withCmsStyle
          disabled={isNonLocalizedDisabled(props.attributes["startDate"])}
          minDate={undefined}
          value={parseDateAndTimeToDateTime(
            timeAndSchedulingData["startDate"],
            timeAndSchedulingData["startTime"]
          ).toISOString()}
          hideTime={timeAndSchedulingData["allDay"]}
          onChange={(value: any, type: any) => {
            if (type === "date") {
              props.onChange(props.name, {
                ...timeAndSchedulingData,
                startDate: value,
              });
            } else if (type === "time") {
              props.onChange(props.name, {
                ...timeAndSchedulingData,
                startTime: value + ":00.000",
              });
            }
          }}
        />
      </ModalInputWrapper>
      <ModalInputWrapper
        label={tCms("event-endTime")}
        description={tCms("event-endDateTimeDescription")}
        nonLocalized={nonLocalized(props.attributes["endDate"])}
      >
        <PbDateTime
          fullWidth
          withCmsStyle
          disabled={isNonLocalizedDisabled(props.attributes["endDate"])}
          minDate={getEndStartDate(
            timeAndSchedulingData["startDate"],
            timeAndSchedulingData["startTime"]
          )}
          value={parseDateAndTimeToDateTime(
            timeAndSchedulingData["endDate"],
            timeAndSchedulingData["endTime"]
          ).toISOString()}
          hideTime={timeAndSchedulingData["allDay"]}
          onChange={(value: any, type: any) => {
            if (type === "date") {
              props.onChange(props.name, {
                ...timeAndSchedulingData,
                endDate: value,
              });
            } else if (type === "time") {
              props.onChange(props.name, {
                ...timeAndSchedulingData,
                endTime: value + ":00.000",
              });
            }
          }}
        />
      </ModalInputWrapper>
      <ModalInputWrapper
        label={tCms("event-allDay")}
        description={tCms("event-fullDayDescription")}
        nonLocalized={nonLocalized(props.attributes["allDay"])}
      >
        <PbCheckBox
          isDisabled={isNonLocalizedDisabled(props.attributes["allDay"])}
          withoutColon
          value={timeAndSchedulingData["allDay"]}
          isSlider={true}
          onChange={() => {
            props.onChange(props.name, {
              ...timeAndSchedulingData,
              startTime: props.attributes["allDay"] ? "00:00:00.000" : null,
              endTime: props.attributes["allDay"] ? "00:00:00.000" : null,
              allDay: !timeAndSchedulingData["allDay"],
            });
          }}
          fullHeight
        />
      </ModalInputWrapper>
      <ModalInputWrapper
        label={tCms("event-repeatInterval")}
        description={tCms("event-repetitionDescription")}
        nonLocalized={nonLocalized(props.attributes["repetitionInterval"])}
      >
        <PbDropdown
          withCmsStyle
          disabled={isNonLocalizedDisabled(
            props.attributes["repetitionInterval"]
          )}
          dropdownList={repetitionIntervalOptions()}
          onChange={(event) => {
            props.onChange(props.name, {
              ...timeAndSchedulingData,
              repetitionInterval: event.target.value,
            });
          }}
          defaultValue={
            timeAndSchedulingData["repetitionInterval"] ??
            props.attributes["repetitionInterval"].default
          }
          itemNameKey={"name"}
          itemValueKey={"value"}
        />
      </ModalInputWrapper>
      {!timeAndSchedulingData["repetitionInterval"] ||
        (timeAndSchedulingData["repetitionInterval"] !== "noRepetition" && (
          <ModalInputWrapper
            label={tCms("event-repeatUntil")}
            description={tCms("event-dateTimeUntilDescription")}
            nonLocalized={nonLocalized(props.attributes["repeatUntilDate"])}
          >
            <PbDateTime
              withCmsStyle
              fullWidth
              disabled={isNonLocalizedDisabled(
                props.attributes["repeatUntilDate"]
              )}
              value={parseDateAndTimeToDateTime(
                timeAndSchedulingData["repeatUntilDate"],
                timeAndSchedulingData["repeatUntilTime"]
              ).toISOString()}
              hideTime={true}
              onChange={(value: any, type: any) => {
                if (type === "date") {
                  props.onChange(props.name, {
                    ...timeAndSchedulingData,
                    repeatUntilDate: value?.toString() ?? null,
                  });
                } else if (type === "time") {
                  props.onChange(props.name, {
                    ...timeAndSchedulingData,
                    repeatUntilTime: value?.toString() + ":00.000" ?? null,
                  });
                }
              }}
            />
          </ModalInputWrapper>
        ))}
    </>
  );
}
