import clsx from "clsx";
import dynamic from "next/dynamic";
import PbInputLabel from "../pbInputLabel/pbInputLabel";

const Checkbox = dynamic(() => import("@mui/material/Checkbox"));
const Switch = dynamic(() => import("@mui/material/Switch"));

export interface PbCheckBoxProps {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * If true a label is shown
   */
  label?: string;
  /**
   * If true the checkbox is schon as a slider, else a default checkbox
   */
  isSlider: boolean;
  /**
   * The checkbox value
   */
  value: boolean;
  /**
   * If true the checkbox is disabled
   */
  isDisabled?: boolean;
  /**
   * Indicates whether the input is mandatory.
   */
  isRequired?: boolean;
  /**
   * If true a colon is added behind the label text
   */
  withoutColon?: boolean;
  /**
   * Indicates whether a line break should be rendered after the label.
   */
  labelInSameRow?: boolean;
  /**
   * Adds a class name to the label
   */
  className?: string;
  /**
   * If true the checkbox takes the full height of its parent and gets centered vertically.
   * This does not work with labelInSameRow.
   */
  fullHeight?: boolean;

  /**
   * sets the size of the checkbox while isSlider=false defaults to 30
   */
  checkBoxFontSize?: number;
  /**
   * id set on the checkbox html element
   */
  id?: string;

  /**
   * An optional tooltip text that provides additional information.
   */
  tooltip?: string;
}

/**
 * Checkbox component
 */
const PbCheckBox = (props: PbCheckBoxProps) => {
  return (
    <>
      {props.isSlider ? (
        <div
          className={clsx(
            "cms-checkbox",
            props.fullHeight ? "h-100 d-flex align-items-center" : ""
          )}
        >
          {props.label && (
            <PbInputLabel
              label={`${props.label}${!props.withoutColon ? `:` : ``}`}
              mandatory={props.isRequired}
              lineBreak={!props.labelInSameRow}
              className={props.className}
              tooltip={props.tooltip?.trim()}
            />
          )}

          <Switch
            id={props.id}
            checked={props.value || false}
            onChange={(e) => {
              props.onChange && props.onChange(e);
            }}
            disabled={props.isDisabled}
            sx={{
              ...(props.labelInSameRow && { marginLeft: "0px !important" }),
            }}
          />
        </div>
      ) : (
        <div
          className={clsx(
            "cms-checkbox",
            props.fullHeight ? "h-100 d-flex align-items-center" : ""
          )}
        >
          {/* cms-checkbox as nonSlider has only default Styling yet */}

          {props.label && (
            <PbInputLabel
              label={`${props.label}${!props.withoutColon ? `:` : ``}`}
              mandatory={props.isRequired}
              lineBreak={!props.labelInSameRow}
              className={props.className}
              tooltip={props.tooltip?.trim()}
            />
          )}

          <Checkbox
            id={props.id}
            disabled={props.isDisabled}
            onChange={(e) => {
              props.onChange && props.onChange(e);
            }}
            checked={props.value}
            // checkbox size
            // https://mui.com/material-ui/react-checkbox/#size
            sx={{
              "& .MuiSvgIcon-root": { fontSize: props.checkBoxFontSize ?? 30 },
              ...(props.labelInSameRow && { marginLeft: "0px !important" }),
            }}
          />
        </div>
      )}
    </>
  );
};

export default PbCheckBox;
