import PbButton from "@/components/input/pbButton/pbButton";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import ModalInputWrapper from "@/components/util/modalInputWrapper";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import { StrapiUploadFile } from "@/types/strapi";
import { MAX_AUDIO_SIZE, MEDIA_TYPE_AUDIO } from "@/utils/constants";
import CmsMediaManagerSelector from "../cmsMediaManager/cmsMediaManagerSelector";

interface CmsMediaSelectorAudioProps {
  aud?: StrapiUploadFile;
  onChange: (onChangeAudioValues: OnChangeAudioValues) => void;
  id?: string;
}

export interface OnChangeAudioValues {
  aud: StrapiUploadFile | null;
}

export default function CmsMediaSelectorAudio(
  props: CmsMediaSelectorAudioProps
) {
  const tCms = useCmsTranslation();
  return (
    <ModalInputWrapper
      label={tCms("audio")}
      description={tCms("mediaSelector-uploadAudioFile", {
        maxAudioSize: MAX_AUDIO_SIZE / 1024 / 1024 + "",
      })}
    >
      <CmsMediaManagerSelector
        id={props.id ? `${props.id}-audio-selector` : "media-audio-selector"}
        mimeTypes={[MEDIA_TYPE_AUDIO]}
        onConfirm={(file) => {
          props.onChange && props.onChange({ aud: file });
        }}
      >
        <PbButton fullWidth className="mb-2">
          {tCms("file-chooseFile")}
        </PbButton>
      </CmsMediaManagerSelector>
      {props.aud ? (
        <>
          <PbButton
            title={tCms("remove")}
            onClick={() => {
              props.onChange && props.onChange({ aud: null });
            }}
            startIcon={
              <PbIcon
                name="trash-light"
                width={25}
                height={25}
                className="svg-fill-white"
              />
            }
          />{" "}
          {props.aud.name}
        </>
      ) : null}
    </ModalInputWrapper>
  );
}
