import useCmsTranslation from "@/hooks/useCmsTranslation";
import { globalConfig } from "@/services/globalConfig/globalConfigService";
import { openDynamicListModalAction } from "@/store/slices/cmsGeneral/cmsGeneralSlice";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { StrapiUploadFile } from "@/types/strapi";
import { Col, Row } from "react-bootstrap";
import CmsGeneralConfigFrame from "../cms/cmsGeneralConfigFrame/cmsGeneralConfigFrame";
import CmsImage from "../cms/cmsImage/cmsImage";
import OptimizeStrapiImage from "../cms/cmsImage/optimizeStrapiImage";
import PbRichTextView from "../pbRichTextView/pbRichTextView";
import SocialMediaList from "../socialMediaList/socialMediaList";
import FooterContentStyle from "./footerContentStyle";

export interface FooterContentProps {
  footerContent: FooterContent;
  cmsEditView: boolean;
  copyrightText?: {
    [name: string]: string;
  };
  richTextClassName: string;
}

export interface FooterContent {
  content: Array<{ img: StrapiUploadFile; richTextContent: string }>;
}

export default function FooterContent(props: FooterContentProps) {
  const dispatch = useAppDispatch();
  const tCms = useCmsTranslation();

  let socialMediaWidth = 0;
  socialMediaWidth = globalConfig?.footer?.socialMediaWidth ?? 290;

  const footerElementsAlignment = globalConfig?.footer
    ?.footerElementsAlignmentLeft
    ? "justify-content-start"
    : "justify-content-around";

  const socialMediaList: { title: string; content: Array<any> } =
    useAppSelector((state) =>
      state.general.dynamicLists
        ? state.general.dynamicLists.find(
            (dynamicList) => dynamicList.name === "socialMedia"
          )
        : []
    );

  return (
    <>
      <Row className={`edge-to-edge-row ${footerElementsAlignment}`}>
        {props.footerContent && props.footerContent.content?.length > 0
          ? props.footerContent.content.map((element, index) => {
              return (
                <Col className="footer-col" key={"footer-col-" + index}>
                  {element.img ? (
                    <Row className="edge-to-edge-row">
                      <Col>
                        <div className="footer-col-image">
                          <OptimizeStrapiImage
                            image={element.img}
                            isMobile={true}
                            widthLimitSettings={400}
                          >
                            <CmsImage
                              width={167}
                              height={73}
                              image={element.img}
                              objectPosition="left"
                            />
                          </OptimizeStrapiImage>
                        </div>
                      </Col>
                    </Row>
                  ) : null}
                  {element.richTextContent ? (
                    <Row className="edge-to-edge-row">
                      <Col>
                        <PbRichTextView
                          className={props.richTextClassName}
                          htmlString={element.richTextContent}
                        />
                      </Col>
                    </Row>
                  ) : null}
                </Col>
              );
            })
          : props.cmsEditView && (
              <Col xs={12}>
                <div className="placeholder-footer">
                  <p>{tCms("footer-addContent")}</p>
                </div>
              </Col>
            )}
        {socialMediaWidth === 0 ? null : (
          <Col className="social-media-column">
            {props.cmsEditView ? (
              <CmsGeneralConfigFrame
                id="pb-footer-social-media"
                smallTitle
                configButtonTitle={tCms("process")}
                frameOnHover
                title={tCms("footer-footerSocialMedia")}
                openContentEditModal={() => {
                  dispatch(
                    openDynamicListModalAction({
                      dynamicList: {
                        dynamicListDisplayName: tCms(
                          "footer-socialMediaElement"
                        ),
                        dynamicListName: "socialMedia",
                        enabledListAttributes: ["title"],
                        enabledListItemAttributes: [
                          "linkUrl",
                          "img",
                          "imgAlt",
                          "imgTitle",
                        ],
                      },
                    })
                  );
                }}
              >
                <SocialMediaList
                  socialMediaList={socialMediaList}
                  richtextClassname={props.richTextClassName}
                />
              </CmsGeneralConfigFrame>
            ) : (
              <SocialMediaList
                socialMediaList={socialMediaList}
                richtextClassname={props.richTextClassName}
              />
            )}
          </Col>
        )}
      </Row>
      <FooterContentStyle
        {...props}
        socialMediaList={socialMediaList}
        socialMediaWidth={socialMediaWidth}
        footerElementsAlignment={footerElementsAlignment}
      />
    </>
  );
}
