import PbGenericModal from "@/components/genericModal/pbGenericModal";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import ModalInputWrapper from "@/components/util/modalInputWrapper";
import PbInput from "@/components/input/pbInput/pbInput";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { createToastError, createToastSuccess } from "@/utils/utilComponents";
import { cmsCreatePageTypeThunk } from "@/store/slices/cmsEdit/cmsPageTypeThunks";
import { showLoadingOverlayAction } from "@/store/slices/general/generalSlice";
import { redirectThunk } from "@/store/slices/general/generalThunks";
import { useRouter } from "next/router";

interface CmsPageTypeCreateModalProps {
  open: boolean;
  onCancel: () => void;
}

interface PageTypeCreateFormState {
  status: "SUCCESS" | "ERROR" | null;
  errorFields: {
    [field: string]: string;
  };
}

const INITIAL_FORM_STATE: PageTypeCreateFormState = {
  status: null,
  errorFields: {},
};

export default function CmsPageTypeCreateModal(
  props: Readonly<CmsPageTypeCreateModalProps>
) {
  const tCms = useCmsTranslation();
  const dispatch = useAppDispatch();
  const router = useRouter();
  const existingPageTypes = useAppSelector(
    (state) => state.cmsGeneral.pageTypes
  );
  const [newPageType, setNewPageType] = useState<{
    name: string;
    contextPath: string;
  }>({
    name: "",
    contextPath: "",
  });

  const [pageTypeCreateFormState, setPageTypeCreateFormState] =
    useState<PageTypeCreateFormState>(INITIAL_FORM_STATE);

  function setErrorState(field: string, messageKey: string) {
    setPageTypeCreateFormState((prevState) => ({
      ...prevState,
      errorFields: {
        ...prevState.errorFields,
        [field]: messageKey,
      },
    }));
  }

  function resetFieldErrors() {
    setPageTypeCreateFormState((prevState) => ({
      ...prevState,
      errorFields: {},
    }));
  }

  const closeModal = () => {
    setPageTypeCreateFormState(INITIAL_FORM_STATE);
    setNewPageType({
      name: "",
      contextPath: "",
    });
    props.onCancel();
  };

  const handlePageTypeCreateEvent = async () => {
    resetFieldErrors();

    if (!newPageType.name || newPageType.name.trim().length === 0) {
      setErrorState("name", "pageType-create-name-error-empty");
      return;
    }
    if (
      existingPageTypes.some(
        (pageType) => pageType.name === newPageType.name
      ) ||
      newPageType.name.trim().toLowerCase() === "page"
    ) {
      setErrorState("name", "pageType-create-name-error-exist");
      return;
    }

    const normalizedContextPath = newPageType.contextPath
      .replace(/[^a-zA-Z0-9äöüÄÖÜß/]/g, "")
      .replace(/\/{2,}/g, "/");

    if (
      normalizedContextPath.startsWith("/") ||
      normalizedContextPath.endsWith("/")
    ) {
      setErrorState("contextPath", "pageType-create-contextPath-error-slashes");
      return;
    }

    try {
      const createPageType = await dispatch(
        cmsCreatePageTypeThunk(newPageType.name, normalizedContextPath)
      );
      createToastSuccess(tCms("pageType-create-success"));
      dispatch(
        showLoadingOverlayAction(tCms("pageType-create-loading-overlay"))
      );
      dispatch(
        redirectThunk(
          () =>
            router.push(
              "/cms/pagetypes/edit/" + createPageType.id,
              undefined,
              {}
            ),
          "/cms/pagetypes/edit/" + createPageType.id
        )
      );
      closeModal();
    } catch (error) {
      createToastError(tCms("pageType-create-error"));
    }
  };

  return (
    <PbGenericModal
      open={props.open}
      title={tCms("pageType-create-title")}
      className="cms-modal"
      muiDialogProps={{
        fullWidth: true,
        maxWidth: "md",
      }}
      onConfirmClick={handlePageTypeCreateEvent}
      onCancelClick={closeModal}
      onBackdropClick={closeModal}
    >
      <div className="cms-modal-input-wrapper-container">
        <ModalInputWrapper
          label={tCms("pageType-create-name")}
          description={tCms("pageType-create-name-description", undefined, " ")}
        >
          <PbInput
            error={!!pageTypeCreateFormState.errorFields?.name}
            errorText={tCms(pageTypeCreateFormState.errorFields?.name)}
            withCmsStyle
            type="text"
            fullWidth
            defaultValue={newPageType?.name ?? ""}
            onChange={(event) => {
              setNewPageType((prevState) => ({
                ...prevState,
                name: event.target.value,
              }));
            }}
          />
        </ModalInputWrapper>
        <ModalInputWrapper
          label={tCms("pageType-create-contextPath")}
          description={tCms(
            "pageType-create-contextPath-description",
            undefined,
            " "
          )}
        >
          <PbInput
            error={!!pageTypeCreateFormState.errorFields?.contextPath}
            errorText={tCms(pageTypeCreateFormState.errorFields?.contextPath)}
            withCmsStyle
            type="text"
            fullWidth
            defaultValue={newPageType?.contextPath ?? ""}
            onChange={(event) => {
              setNewPageType((prevState) => ({
                ...prevState,
                contextPath: event.target.value.toLowerCase(),
              }));
            }}
          />
        </ModalInputWrapper>
      </div>
    </PbGenericModal>
  );
}
