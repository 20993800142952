import PbTimeInput from "@/components/input/pbTimeInput/pbTimeInput";

interface CmsAttributeTimeProps {
  name: string;
  value: string;
  onChange: (name: string, value: string) => void;
  isNonLocalizedDisabled?: boolean;
}

export default function CmsAttributeTime(
  props: Readonly<CmsAttributeTimeProps>
) {
  return (
    <PbTimeInput
      fullWidth
      withCmsStyle
      disabled={props.isNonLocalizedDisabled}
      value={props.value}
      onChange={(value: any) => props.onChange(props.name, value + ":00.000")}
    />
  );
}
