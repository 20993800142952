import useCmsTranslation from "@/hooks/useCmsTranslation";
import React, { useEffect, useState } from "react";
import { CmsPropertyRelationValue, StrapiUploadFile } from "@/types/strapi";
import CmsImage from "@/components/cms/cmsImage/cmsImage";
import FilePlaceholderIcon from "@/components/util/filePlaceholderIcon";
import PbButton from "@/components/input/pbButton/pbButton";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import LoadingText from "@/components/loadingText/loadingText";
import { getFileById } from "@/services/cmsMediaManager/cmsMediaManagerService";
import CmsMediaManagerSelector from "@/components/cms/cmsMediaManager/cmsMediaManagerSelector";
import { MediaManagerFilterOption } from "@/components/cms/cmsMediaManager/cmsMediaManagerFilter";

interface CmsAttributeMediaProps {
  name: string;
  value: StrapiUploadFile | null;
  allowedTypes?: MediaManagerFilterOption[];
  onChange: (name: string, value: StrapiUploadFile | null) => void;
  isNonLocalizedDisabled?: boolean;
}

export default function CmsAttributeMedia(
  props: Readonly<CmsAttributeMediaProps>
) {
  const tCms = useCmsTranslation();
  const [mediaFile, setMediaFile] = useState<StrapiUploadFile | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const currentValue: StrapiUploadFile | null = props.value;

  useEffect(() => {
    if (currentValue === null) {
      setMediaFile(null);
    } else {
      const getMediaFile = async () => {
        try {
          const selectedMediaFile = await getFileById(currentValue.id);
          setMediaFile(selectedMediaFile);
          setIsLoading(false);
        } catch (error) {
          setMediaFile(null);
          setIsLoading(false);
        }
      };
      setIsLoading(true);
      getMediaFile();
    }
  }, [currentValue]);

  return (
    <>
      <div>
        <CmsMediaManagerSelector
          disabled={props.isNonLocalizedDisabled}
          onConfirm={(file: StrapiUploadFile) => {
            if (file) {
              if (currentValue === null || file.id !== currentValue.id) {
                setMediaFile(file);
                props.onChange(props.name, file);
              }
            } else {
              setMediaFile(null);
              props.onChange(props.name, null);
            }
          }}
          mimeTypes={props.allowedTypes}
        />
      </div>
      {mediaFile ? (
        <>
          <div className="mt-3 d-flex">
            <div className="mr-auto">
              {mediaFile?.mime?.includes("image") ? (
                <CmsImage
                  image={{
                    ...mediaFile,
                    formats: undefined,
                    url: mediaFile.formats?.thumbnail
                      ? mediaFile.formats.thumbnail.url
                      : mediaFile.url,
                  }}
                  format="thumbnail"
                  objectFit="cover"
                  height={80}
                  width={80}
                />
              ) : (
                <FilePlaceholderIcon
                  mimeType={mediaFile.mime}
                  height={80}
                  width={80}
                />
              )}
            </div>
            <div className="m-auto">
              <PbButton
                disabled={props.isNonLocalizedDisabled}
                title={tCms("remove")}
                onClick={() => {
                  props.onChange(props.name, null);
                }}
                startIcon={
                  <PbIcon
                    name="trash-light"
                    width={20}
                    height={20}
                    className="svg-fill-white"
                  />
                }
              ></PbButton>
            </div>
          </div>
          <div className="mt-3 d-flex">
            <p className="text-truncate cms-modal-text" title={mediaFile.name}>
              {mediaFile.name}
            </p>
          </div>
        </>
      ) : (
        <>{!mediaFile && isLoading && currentValue && <LoadingText />}</>
      )}
    </>
  );
}
