export const CmsContentFrameStyle = () => {
  return (
    <style jsx global>{`
      .draft-page .content-frame:first-of-type .frame-bar {
        top: 15px;
      }

      .content-frame {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        padding: 0;

        &.content-frame-border::after {
          content: "";
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          border: 2px solid transparent;
          transition: border-color 0.3s;
          pointer-events: none;
        }
        &.content-frame-border:hover::after,
        &.content-frame-border:focus-within::after {
          border-color: var(--pb-cms-color);
          border-radius: 5px;
        }

        &:hover,
        &:focus-within {
          .frame-bar {
            pointer-events: none;
            .right-controls {
              display: flex;
              transition: display 0.3s;
            }
            .left-controls {
              display: flex;
              transition: display 0.3s;
            }
            .title {
              display: flex;
              transition: display 0.3s;
            }
          }
        }

        .frame-bar {
          display: flex;
          justify-content: space-between;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: fit-content;
          .cms-config-frame-btn {
            background-color: var(--pb-cms-color);
            pointer-events: all;
            margin: 1px 1px 1px 0;
            &.no-text {
              min-width: 33px;
              max-height: 37px;
              border-radius: 0px;
            }
            &:first-of-type {
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;
            }
            &:last-of-type {
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
            }
          }
        }

        .frame-bar .left-controls {
          display: none;
          z-index: 990;
          top: 0;
          left: 0;
          position: absolute;
          transform: translate(10px, -50%);
        }
        .frame-bar .right-controls {
          display: none;
          z-index: 990;
          top: 0;
          left: 100%;
          position: absolute;
          transform: translate(calc(-100% - 10px), -50%);
        }

        .frame-bar .title {
          display: none;
          z-index: 990;
          top: 0;
          left: 100%;
          position: absolute;
          transform: translate(-50%, -50%);
          text-align: center;
          color: #fff;
          top: 0;
          left: 50%;
          position: absolute;
          z-index: 990;
          font-size: 16px;
          padding: 0.5rem 5rem;
          border-radius: 5px;
          background-color: var(--pb-cms-color);

          .cms-config-frame-btn {
            height: 21px;
            padding: 0;
            min-width: 16px;
            margin-left: 8px;
            margin-right: 0px;
          }
        }
      }
    `}</style>
  );
};

export default CmsContentFrameStyle;
