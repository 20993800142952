import CmsAddButton from "@/components/cms/cmsAddButton/cmsAddButton";
import HtmlEditorModal from "@/components/input/pbRichTextEditor/htmlEditorModal/htmlEditorModal";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import useScopedClassName from "@/hooks/useScopedClassName";
import { updateAttributeAction } from "@/store/slices/cmsEdit/cmsEditSlice";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { ContentElementHtmlStoreSetting } from "@/types/ceSettings/ceSettings";
import { CEHtml } from "@/types/content-elements";
import clsx from "clsx";
import js_beautify from "js-beautify";
import React, { useEffect, useRef, useState } from "react";
import ContentElementWrapper from "../wrapper/contentElementWrapper";
import HtmlContentElementStyleGlobal from "./htmlContentElementStyleGlobal";
import HtmlContentElementStyleScoped from "./htmlContentElementStyleScoped";

export interface HtmlContentElementProps {
  content: CEHtml;
  position: number;
  ceSettings?: ContentElementHtmlStoreSetting;
  isLastElement: boolean;
  isMobile: boolean;
  isFirstElement: boolean;
}

export const HTML_CE_NAME = "htmlCE";

export default React.memo(function HtmlContentElement(
  props: HtmlContentElementProps
) {
  const HTML_SCOPED_CLASS = useScopedClassName(
    HTML_CE_NAME,
    props.content,
    props.position
  );

  const tCms = useCmsTranslation();
  const editView = useAppSelector((state) => state.cmsGeneral.editView);
  const dispatch = useAppDispatch();

  const htmlDivRef = useRef<HTMLDivElement | null>(null);

  const [htmlEditorValue, setHtmlEditorValue] = useState(props.content.html);
  const [showHtmlEditorModal, setShowHtmlEditorModal] = useState(false);

  // runs on mount and everytime the provided html content changes.
  // runs after HTML was changed and confirmed throught the HTML editor.
  useEffect(() => {
    // clear any existing children nodes
    let localHtmlDivRef = htmlDivRef.current;
    if (localHtmlDivRef) {
      localHtmlDivRef.innerHTML = "";
    }

    let ceHtmlDoc = null;
    ceHtmlDoc = new DOMParser().parseFromString(
      props.content.html,
      "text/html"
    );
    ceHtmlDoc.body.childNodes.forEach(function (node: Node) {
      if (node.nodeType === Node.TEXT_NODE && node.textContent) {
        htmlDivRef.current?.appendChild(node.cloneNode(true));
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        const elementNode = node as HTMLElement;
        if (elementNode.tagName === "SCRIPT") {
          const newScriptElement = document.createElement("script");

          for (const attribute of elementNode.attributes) {
            newScriptElement.setAttribute(attribute.name, attribute.value);
          }

          if (elementNode.textContent) {
            newScriptElement.textContent = elementNode.textContent;
          }

          htmlDivRef.current?.appendChild(newScriptElement);
        } else {
          htmlDivRef.current?.appendChild(elementNode.cloneNode(true));
        }
      }
    });

    return () => {
      if (localHtmlDivRef) {
        localHtmlDivRef.innerHTML = "";
      }
    };
  }, [props.content.html]);

  return (
    <>
      <ContentElementWrapper
        name={HTML_CE_NAME}
        scopedClassName={HTML_SCOPED_CLASS}
        content={props.content}
        ceSettings={props.ceSettings}
        isFirstElement={props.isFirstElement}
        isLastElement={props.isLastElement}
        position={props.position}
      >
        <div className={clsx(HTML_CE_NAME, HTML_SCOPED_CLASS)}>
          {/* This div will contain the actual HTML provided by the content manager */}
          <div
            className={HTML_CE_NAME + "-" + props.position + "-html"}
            ref={htmlDivRef}
          ></div>
          {editView && (
            <>
              <CmsAddButton
                id={`ce-html-${props.position}-add-button`}
                height={60}
                title={
                  props?.content?.html
                    ? tCms("editSourceCode")
                    : tCms("addSourceCode")
                }
                text={
                  props?.content?.html
                    ? tCms("editSourceCode")
                    : tCms("addSourceCode")
                }
                onClick={() => {
                  setShowHtmlEditorModal(true);
                }}
                disablePlusIcon
              />
              <HtmlEditorModal
                value={htmlEditorValue}
                setValue={setHtmlEditorValue}
                show={showHtmlEditorModal}
                setShow={setShowHtmlEditorModal}
                onConfirm={(value) =>
                  dispatch(
                    updateAttributeAction({
                      attributePath: `draftPage.content[${props.position}].html`,
                      value: value,
                    })
                  )
                }
                reset={() => {
                  setHtmlEditorValue(props.content.html);
                }}
                promptComponent={
                  <p className="pb-1 cms-modal-text">
                    {tCms("htmlContentElement-prompt")}
                  </p>
                }
                formatHtmlEditorValue={() =>
                  setHtmlEditorValue(js_beautify.html_beautify(htmlEditorValue))
                }
                hintComponent={
                  <p className="pt-1 cms-modal-text" style={{ color: "red" }}>
                    {tCms("htmlContentElement-hint")}
                  </p>
                }
              />
            </>
          )}
        </div>
      </ContentElementWrapper>
      <HtmlContentElementStyleGlobal {...props} />
      <HtmlContentElementStyleScoped
        {...props}
        scopedSelector={HTML_SCOPED_CLASS}
      />
    </>
  );
});
