import ResponseResult from "@/types/classes/ResponseResult";
import { axiosGetRequestClientSide } from "@/utils/axiosClientUtil";
import { getNextJsApiURL } from "@/utils/api";

export const getRelationOptions = async (locale: string, model: string) => {
  const relationOptions: ResponseResult<any> = await axiosGetRequestClientSide(
    getNextJsApiURL(
      `/cms/manage/pabu/attributes/relation-options?locale=${locale}&model=${model}`
    )
  );
  if (!relationOptions.success) {
    global.log.warn("[getRelationOptions] could not get relation list");
    return [];
  }

  return relationOptions.data as Array<any>;
};
