import PbInput from "@/components/input/pbInput/pbInput";

interface CmsAttributeIntegerProps {
  name: string;
  value: number;
  onChange: (name: string, value: string) => void;
  isNonLocalizedDisabled?: boolean;
}

export default function CmsAttributeInteger(
  props: Readonly<CmsAttributeIntegerProps>
) {
  return (
    <PbInput
      withCmsStyle
      type="number"
      fullWidth
      defaultValue={props.value}
      disabled={props.isNonLocalizedDisabled}
      onChange={(e) => {
        props.onChange(props.name, e.target.value);
      }}
    />
  );
}
