import PbButton from "@/components/input/pbButton/pbButton";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import useConfirmModal from "@/hooks/useConfirmModal";
import { filterAvailableElements } from "@/services/pageService/pageService";
import {
  moveInArrayAction,
  removeFromArrayAction,
} from "@/store/slices/cmsEdit/cmsEditSlice";
import { cmsCloneContentElementThunk } from "@/store/slices/cmsEdit/cmsPageThunks";
import {
  hideConfigModal,
  showAddContentModal,
  showConfigModal,
} from "@/store/slices/cmsGeneral/cmsGeneralSlice";
import { useAppDispatch, useAppSelector } from "@/store/store";
import React from "react";
import CmsContentFrameStyle from "./cmsContentFrameStyle";

export interface CMSContentFrameProps {
  children: React.ReactNode;
  title: string;
  position: number;
  configModalButton?: boolean;
  noDeleteButton?: boolean;
  noCloneButton?: boolean;
  noInsertButton?: boolean;
  additionalButtons?: Array<React.ReactNode>;
}

export default function CMSContentFrame(props: CMSContentFrameProps) {
  const tCms = useCmsTranslation();
  const editView = useAppSelector((state) => state.cmsGeneral.editView);
  const draftPageContentLength = useAppSelector(
    (state) => state.cmsEdit.data?.draftPage?.content?.length
  );
  const currentElement = useAppSelector(
    (state) => state.cmsEdit.data.draftPage.content[props.position]
  );
  const availableElements = useAppSelector(
    (state) => state.cmsEdit.data?.availableElements
  );
  const pageType = useAppSelector(
    (state) => state.cmsEdit.data?.draftPage?.type
  );
  const dispatch = useAppDispatch();
  const { showConfirmModal } = useConfirmModal(() => {
    dispatch(
      removeFromArrayAction({
        attributePath: `draftPage.content`,
        position: props.position,
      })
    );
  });

  const moveElement = (direction: "up" | "down") => {
    dispatch(hideConfigModal());
    if (direction === "up") {
      dispatch(
        moveInArrayAction({
          attributePath: `draftPage.content[${props.position}]`,
          direction: "up",
        })
      );
    } else {
      dispatch(
        moveInArrayAction({
          attributePath: `draftPage.content[${props.position}]`,
          direction: "down",
        })
      );
    }
  };

  const openConfigModal = () => {
    dispatch(showConfigModal(props.position));
  };

  const openAddCEModal = () => {
    dispatch(showAddContentModal(props.position + 1));
  };

  const deleteClick = () => {
    showConfirmModal({
      title: tCms("contentFrame-deleteContentElementConfirmation", {
        contentElement: props.title,
      }),
      content: tCms("contentFrame-deleteContentElementConfirmationLong", {
        contentElement: props.title,
      }),
      acceptBtnText: tCms("delete"),
      denyBtnText: tCms("cancel"),
      icon: "triangle-exclamation-light",
      isCms: true,
    });
  };

  const cloneClick = () => {
    dispatch(cmsCloneContentElementThunk(props.position));
  };

  const canAddNewCe =
    filterAvailableElements(availableElements, pageType)?.length > 0;

  return (
    <>
      {editView ? (
        <div
          className="content-frame content-frame-border"
          id={`ce-${props.title}-${props.position}`}
          tabIndex={0}
        >
          <div className="frame-bar">
            <div className="left-controls">
              {props.position > 0 ? (
                <PbButton
                  className="cms-config-frame-btn"
                  title={tCms("contentFrame-up")}
                  onClick={() => moveElement("up")}
                  startIcon={
                    <PbIcon
                      name="angle-up-light"
                      width={15}
                      height={15}
                      className="svg-fill-white"
                    />
                  }
                />
              ) : null}
              {draftPageContentLength > props.position + 1 ? (
                <PbButton
                  className="cms-config-frame-btn"
                  title={tCms("contentFrame-down")}
                  onClick={() => moveElement("down")}
                  startIcon={
                    <PbIcon
                      name="angle-down-light"
                      width={15}
                      height={15}
                      className="svg-fill-white"
                    />
                  }
                />
              ) : null}
            </div>
            <div className="title">
              {props.title}
              {currentElement.cfgAnchorName && (
                <PbButton
                  className="cms-config-frame-btn"
                  title={currentElement.cfgAnchorName}
                  onClick={() => openConfigModal()}
                  startIcon={
                    <PbIcon
                      name="anchor-light"
                      width={16}
                      height={16}
                      className="svg-fill-white"
                    />
                  }
                />
              )}
            </div>
            <div className="right-controls">
              {!props.noInsertButton && canAddNewCe && (
                <PbButton
                  className="cms-config-frame-btn"
                  title={tCms("contentFrame-insert-ce")}
                  onClick={() => openAddCEModal()}
                  startIcon={
                    <PbIcon
                      name="plus-light"
                      width={20}
                      height={20}
                      className="svg-fill-white"
                    />
                  }
                />
              )}
              {!props.noCloneButton && (
                <PbButton
                  className="cms-config-frame-btn"
                  title={tCms("contentFrame-clone-ce")}
                  onClick={() => cloneClick()}
                  startIcon={
                    <PbIcon
                      name="clone-light"
                      width={20}
                      height={20}
                      className="svg-fill-white"
                    />
                  }
                />
              )}
              {props.additionalButtons &&
                props.additionalButtons?.map((element, index) => (
                  <React.Fragment key={index}>{element}</React.Fragment>
                ))}
              {!props.noDeleteButton && (
                <PbButton
                  className="cms-config-frame-btn"
                  title={tCms("delete")}
                  onClick={() => deleteClick()}
                  startIcon={
                    <PbIcon
                      name="trash-light"
                      width={20}
                      height={20}
                      className="svg-fill-white"
                    />
                  }
                />
              )}
              {props.configModalButton ? (
                <PbButton
                  className="cms-config-frame-btn"
                  title={tCms("settings")}
                  onClick={() => openConfigModal()}
                  startIcon={
                    <PbIcon
                      name="gear-light"
                      width={20}
                      height={20}
                      className="svg-fill-white"
                    />
                  }
                />
              ) : null}
            </div>
          </div>
          {props.children}
        </div>
      ) : (
        <>{props.children}</>
      )}
      <CmsContentFrameStyle />
    </>
  );
}
