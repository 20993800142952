import PbButton from "@/components/input/pbButton/pbButton";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import React from "react";

interface OverlayActionWrapperProps {
  children: React.ReactNode;
  onDelete?: () => void;
  onClick?: () => void;
  disableOverlay?: boolean;
  cmsPosition?: number;
}
export default function OverlayActionButtons(props: OverlayActionWrapperProps) {
  const deleteEvent = (event: any) => {
    if (props.onDelete) {
      props.onDelete();
    }
    event.stopPropagation();
  };

  return (
    <>
      {!props.disableOverlay ? (
        <div className="overlay-action-wrapper" onClick={props.onClick} id={props.cmsPosition ? `overlay-action-wrapper-${props.cmsPosition}` : "overlay-action-wrapper"}>
          {props.children}
          <div className="overlay-action-overlay">
            {props.onDelete && (
              <PbButton
                className="overlay-action-button"
                onClick={deleteEvent}
                startIcon={
                  <PbIcon
                    name="trash-light"
                    width={20}
                    height={20}
                    className="svg-fill-white"
                  />
                }
              ></PbButton>
            )}
          </div>
        </div>
      ) : (
        props.children
      )}
      <style jsx>{`
        .overlay-action-wrapper {
          position: relative;
          display: inline-block;
          width: 100%;
          cursor: pointer;
        }

        .overlay-action-overlay {
          position: absolute;
          top: 0;
          left: 0;
          padding: 10px;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          display: none;
        }

        .overlay-action-wrapper:hover .overlay-action-overlay {
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
        }

        .overlay-action-button {
          background: none;
          border: none;
          color: white;
          margin: 0 10px;
          cursor: pointer;
        }
      `}</style>
    </>
  );
}
