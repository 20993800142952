import CmsAttributeRenderer from "@/components/cms/cmsAttributes/cmsAttributeRenderer";
import CmsAttributeTimeAndScheduling from "@/components/cms/cmsAttributes/components/cmsAttributeTimeAndScheduling";

interface CmsAttributesRendererProps {
  data: any;
  attributes: any;
  nestedComponent?: string;
  localized?: boolean;
  onChange: (name: string, value: any) => void;
}

export type AttributeValueEntry = {
  name: string;
  value: any;
  attributes: {
    type: string;
    sort?: number;
    tab?: string;
    [key: string]: any;
  };
};

export default function CmsAttributes(
  props: Readonly<CmsAttributesRendererProps>
) {
  const attributeValues = (): AttributeValueEntry[] => {
    if (!props.data) {
      return [];
    }

    const entries = Object.entries(props.attributes).map(([key, value]) => {
      const attrValue = props.nestedComponent
        ? props.data[props.nestedComponent]?.[key] ?? null
        : props.data[key];
      return {
        name: key,
        value: attrValue,
        attributes: value,
      } as AttributeValueEntry;
    });

    return entries.sort((a, b) => {
      if (!a.attributes.sort && !b.attributes.sort) {
        return 0;
      }
      if (!a.attributes.sort) {
        return 1;
      }
      if (!b.attributes.sort) {
        return -1;
      }
      return a.attributes.sort - b.attributes.sort;
    });
  };

  return (
    <div className="cms-modal-input-wrapper-container">
      {attributeValues().map((attr) => {
        const localized =
          attr.attributes["pluginOptions"]?.["i18n"]?.["localized"];

        // CUSTOM LOGIC ######
        if (attr.attributes.component === "pb.tmndschdlng") {
          return (
            <CmsAttributeTimeAndScheduling
              key={attr.name}
              name={attr.name}
              data={props.data}
              attributes={attr.attributes.attributes}
              onChange={props.onChange}
            />
          );
        }
        if (attr.attributes.type === "component") {
          return (
            <CmsAttributes
              key={attr.name}
              data={props.data}
              attributes={attr.attributes.attributes}
              nestedComponent={attr.name}
              localized={localized}
              onChange={(name, value) => {
                props.onChange(attr.name, {
                  ...attr.value,
                  [name]: value,
                });
              }}
            />
          );
        }
        // #######

        return (
          <CmsAttributeRenderer
            key={attr.name}
            entry={attr}
            localized={props.localized ?? localized}
            onChange={props.onChange}
            idPrefix={`attribute${
              props.nestedComponent ? "-" + props.nestedComponent : ""
            }`}
          />
        );
      })}
    </div>
  );
}
