import {
  createPageTypeRequest,
  deletePateTypeRequest,
  updatePageTypeRequest,
} from "@/services/cmsPageTypeService/cmsPageTypeService";
import { cmsTranslate } from "@/services/cmsTranslation/cmsTranslationService";
import { ReduxThunkAction } from "@/store/store";
import {
  hideLoadingOverlayAction,
  showLoadingOverlayAction,
} from "../general/generalSlice";

export const cmsUpdatePageTypeThunk =
  (): ReduxThunkAction => async (dispatch, getState) => {
    dispatch(
      showLoadingOverlayAction(cmsTranslate("pageType-updatingPagePageType"))
    );
    const cmsSavePageTypeResult = await updatePageTypeRequest(
      getState().cmsEdit.data?.editPageType?.id,
      getState().cmsEdit.data?.editPageType
    );
    dispatch(hideLoadingOverlayAction());
    if (!cmsSavePageTypeResult.success) {
      return Promise.reject();
    }

    return Promise.resolve();
  };

export const cmsCreatePageTypeThunk =
  (name: string, contextPath?: string): ReduxThunkAction =>
  async (dispatch, getState) => {
    dispatch(
      showLoadingOverlayAction(cmsTranslate("pageType-create-loading-overlay"))
    );
    const cmsCreatePageTypeResult = await createPageTypeRequest({
      name,
      contextPath,
    });
    dispatch(hideLoadingOverlayAction());
    return cmsCreatePageTypeResult.success
      ? Promise.resolve(cmsCreatePageTypeResult.data)
      : Promise.reject();
  };

export const cmsDeletePageTypeThunk =
  (id: number): ReduxThunkAction =>
  async (dispatch, getState) => {
    dispatch(
      showLoadingOverlayAction(cmsTranslate("pageType-delete-loading-overlay"))
    );
    const cmsDeletePageTypeResult = await deletePateTypeRequest(id);
    dispatch(hideLoadingOverlayAction());
    return cmsDeletePageTypeResult.success
      ? Promise.resolve()
      : Promise.reject();
  };
