import PbButton from "@/components/input/pbButton/pbButton";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import useConfirmModal from "@/hooks/useConfirmModal";
import { cmsUpdatePageTypeThunk } from "@/store/slices/cmsEdit/cmsPageTypeThunks";
import {
  disableBeforeunloadAction,
  showLoadingOverlayAction,
} from "@/store/slices/general/generalSlice";
import { redirectThunk } from "@/store/slices/general/generalThunks";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { createToastError } from "@/utils/utilComponents";
import Box from "@mui/material/Box";
import { useRouter } from "next/router";
import CmsTopBarLeftControlsBackButton from "./cmsTopBarLeftControlsBackButton";
import CmsPageTypeCreateModal from "@/components/cms/cmsPageType/cmsPageTypeCreateModal";
import { useState } from "react";

/**
 * CmsInputLabel component represents a label for an input field.
 * @param props - The component properties.
 * @returns A JSX element representing the CmsInputLabel.
 */
const CmsTopBarLeftControlsPageType = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const editMode = useAppSelector((state) => state.cmsGeneral.editMode);
  const router = useRouter();
  const tCms = useCmsTranslation();

  const { showConfirmModal } = useConfirmModal(async () => {
    dispatch(showLoadingOverlayAction());
    dispatch(disableBeforeunloadAction());
    dispatch(
      redirectThunk(() => router.push("/cms/pagetypes"), "/cms/pagetypes")
    );
  });

  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);

  return (
    <Box sx={{ display: "flex", flexGrow: 1 }}>
      {editMode ? (
        <>
          <PbButton
            className="cms-top-bar-btn mx-1"
            showOnlyIconBelowWidth={1450}
            onClick={async () => {
              try {
                await dispatch(cmsUpdatePageTypeThunk());
                dispatch(disableBeforeunloadAction());
                dispatch(showLoadingOverlayAction());
                dispatch(redirectThunk(() => router.push("/cms/pagetypes")));
              } catch (error) {
                global.log.error(error);
                createToastError(tCms("genericError"));
              }
            }}
            color="primary"
            startIcon={
              <PbIcon
                name="floppy-disk-light"
                width={20}
                height={20}
                className="svg-fill-white"
              />
            }
          >
            {tCms("save")}
          </PbButton>
          <PbButton
            className="cms-top-bar-btn mx-1"
            showOnlyIconBelowWidth={1450}
            onClick={() => {
              showConfirmModal({
                title: tCms("stopEditing"),
                content:
                  tCms("confirmStopEditing") +
                  " \n " +
                  tCms("unsavedChangesWillBeLost"),
                acceptBtnText: tCms("yes"),
                denyBtnText: tCms("no"),
                icon: "triangle-exclamation-light",
              });
            }}
            color="secondary"
            startIcon={
              <PbIcon
                name="xmark-light"
                width={20}
                height={20}
                className="svg-fill-white"
              />
            }
          >
            {tCms("end")}
          </PbButton>
        </>
      ) : (
        <>
          <CmsTopBarLeftControlsBackButton />
          <PbButton
            className="cms-top-bar-btn mx-1"
            showOnlyIconBelowWidth={1450}
            color="primary"
            startIcon={
              <PbIcon
                name="plus-light"
                width={20}
                height={20}
                className="svg-fill-white"
              />
            }
            onClick={() => setCreateModalOpen(true)}
          >
            {tCms("pageType-create")}
          </PbButton>
          <CmsPageTypeCreateModal
            open={createModalOpen}
            onCancel={() => setCreateModalOpen(false)}
          />
        </>
      )}
    </Box>
  );
};

export default CmsTopBarLeftControlsPageType;
