import ResponseResult from "@/types/classes/ResponseResult";
import { PbPageType } from "@/types/strapi";
import { getNextJsApiURL } from "@/utils/api";
import {
  axiosDeleteRequestClientSide,
  axiosPostRequestClientSide,
} from "@/utils/axiosClientUtil";
import { getLocalizedValue } from "@/utils/localizationUtil";

/**
 * returns the localized pagetype name with the
 * context path if it exists
 *
 * @param pageType
 * @param locale
 * @returns
 */
export const getPageTypeNameLocalized = (
  pageType: PbPageType,
  locale: string,
  showContextPath?: boolean
): string | null => {
  let pageTypeName = getLocalizedValue(pageType?.localizedName, locale);
  if (pageTypeName) {
    pageTypeName = pageTypeName;
  } else {
    pageTypeName = pageType.name;
  }

  if (showContextPath && pageType.contextPath) {
    pageTypeName = pageTypeName + ` (${pageType.contextPath})`;
  }

  return pageTypeName;
};

export const updatePageTypeRequest = async (
  id: number,
  data: PbPageType
): Promise<ResponseResult<any>> => {
  const pageTypeResult: ResponseResult<any> = await axiosPostRequestClientSide(
    getNextJsApiURL(`/cms/manage/pabu/page-type/update/${id}`),
    data
  );
  return pageTypeResult;
};

export const createPageTypeRequest = async (data: {
  name: string;
  contextPath?: string;
}): Promise<ResponseResult<any>> => {
  const pageTypeCreateResult: ResponseResult<any> =
    await axiosPostRequestClientSide(
      getNextJsApiURL(`/cms/manage/pabu/page-types`),
      data
    );
  return pageTypeCreateResult;
};

export const deletePateTypeRequest = async (
  id: number
): Promise<ResponseResult<any>> => {
  const pageTypeDeleteResult: ResponseResult<any> =
    await axiosDeleteRequestClientSide(
      getNextJsApiURL(`/cms/manage/pabu/page-types/${id}`)
    );
  return pageTypeDeleteResult;
};
