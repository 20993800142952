import LanguageSelector from "@/components/languageSelector/languageSelector";
import NavigationBrand from "@/components/navigation/navigationBrand";
import NavigationMobileItem from "@/components/navigation/navigationMobile/navigationMobileItem";
import NavigationMobileStyle from "@/components/navigation/navigationMobile/navigationMobileStyle";
import SearchBar from "@/components/search/searchBar/searchBar";
import SearchButton from "@/components/search/searchButton/searchButton";
import { globalConfig } from "@/services/globalConfig/globalConfigService";
import {
  hideSearchBar,
  showSearchBar,
} from "@/store/slices/general/generalSlice";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { NavigationElement } from "@/types/navigation";
import Close from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { Drawer, IconButton, List } from "@mui/material";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import { useState } from "react";

interface NavigationMobileProps {
  navigation: NavigationElement;
}

type DrawerAnchorDirection = "left" | "top" | "right" | "bottom" | undefined;

export default function NavigationMobile(props: NavigationMobileProps) {
  const searchBarActive = useAppSelector(
    (state) => state.general.navigation.searchBarActive
  );
  const viewportPreview = useAppSelector(
    (state) => state.cmsGeneral.viewportPreview
  );
  const dispatch = useAppDispatch();
  const drawerAnchor =
    globalConfig?.navigation?.mobileDrawerDropDirection ?? "left";
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const { t: tPublic } = useTranslation("public");

  return (
    <>
      <div className={clsx("navigation-mobile", searchBarActive && "search")}>
        {props.navigation.logo && (
          <div className="navigation-mobile-logo">
            <NavigationBrand
              logo={props.navigation.logo}
              url={props.navigation.logoUrl}
              isMobile
            />
          </div>
        )}
        <div className="navigation-mobile-spacer"></div>
        <div className="navigation-mobile-action-group">
          {globalConfig?.search.searchEnabled && (
            <SearchButton
              hidden={searchBarActive}
              toggleSearchBar={() => dispatch(showSearchBar())}
            />
          )}
          {globalConfig?.multilanguage.languageSwitchEnabled && (
            <LanguageSelector />
          )}
          <div>
            <IconButton
              disabled={viewportPreview ? true : false}
              className="navigation-mobile-menu-icon"
              size="large"
              aria-label={tPublic("mobile-navbar-toggle")}
              aria-controls="menu-appbar"
              aria-expanded={drawerOpen}
              aria-haspopup="true"
              onClick={() => setDrawerOpen(true)}
              color="inherit"
            >
              <MenuIcon sx={{ width: "1.5em", height: "1.5em" }} />
            </IconButton>
          </div>
        </div>
        <div className={"navigation-search"}>
          <SearchBar
            onSubmit={() => dispatch(hideSearchBar())}
            toggleSearchBar={() => dispatch(hideSearchBar())}
            asContentElement={false}
          />
        </div>
      </div>
      <Drawer
        PaperProps={{ className: "navigation-mobile-menu" }}
        style={{ width: "100%", maxWidth: "100%" }}
        anchor={drawerAnchor as DrawerAnchorDirection}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        className={clsx(drawerOpen && "drawer-open")}
      >
        <div className="navigation-mobile-header">
          {props.navigation.logo && (
            <div className="navigation-mobile-logo">
              <NavigationBrand
                logo={props.navigation.logo}
                url={props.navigation.logoUrl}
                isMobile
                logoRatio={2}
              />
            </div>
          )}
          <div>
            <IconButton
              size="large"
              color="inherit"
              className="close-drawer-button"
              onClick={() => setDrawerOpen(false)}
            >
              <Close />
            </IconButton>
          </div>
        </div>
        <List component="nav" disablePadding>
          {props.navigation.pages.map((page, index) => {
            return (
              <NavigationMobileItem
                page={page}
                level={1}
                onNavigateEvent={() => setDrawerOpen(false)}
                key={`nav-mobile-${page.id}-${index}`}
              />
            );
          })}
        </List>
      </Drawer>
      <NavigationMobileStyle />
    </>
  );
}
