import PbButton from "@/components/input/pbButton/pbButton";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import { addEmptyDynamicListItemThunk } from "@/store/slices/cmsEdit/cmsDynamicListThunks";
import { useAppDispatch } from "@/store/store";
import CmsDynamicListItemCreatorStyle from "./cmsDynamicListItemCreatorStyle";

function CmsDynamicListItemCreator() {
  const tCms = useCmsTranslation();
  const dispatch = useAppDispatch();

  return (
    <>
      <div className={`cms-dynamic-list-item-creator`}>
        <PbButton
          id="cms-dynamic-list-item-creator-add-element"
          className="cms-btn-add-content"
          title={tCms("createNewElement")}
          onClick={() => dispatch(addEmptyDynamicListItemThunk())}
          startIcon={
            <PbIcon
              name="plus-light"
              width={15}
              height={15}
              className="svg-fill-white"
            />
          }
        >
          {tCms("createNewElement")}
        </PbButton>
      </div>
      <CmsDynamicListItemCreatorStyle />
    </>
  );
}

export default CmsDynamicListItemCreator;
