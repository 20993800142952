import CmsAttributeString from "@/components/cms/cmsAttributes/inputs/cmsAttributeString";
import CmsAttributeText from "@/components/cms/cmsAttributes/inputs/cmsAttributeText";
import CmsAttributeRichText from "@/components/cms/cmsAttributes/inputs/cmsAttributeRichText";
import CmsAttributeBoolean from "@/components/cms/cmsAttributes/inputs/cmsAttributeBoolean";
import CmsAttributeInteger from "@/components/cms/cmsAttributes/inputs/cmsAttributeInteger";
import CmsAttributeDate from "@/components/cms/cmsAttributes/inputs/cmsAttributeDate";
import CmsAttributeDateTime from "@/components/cms/cmsAttributes/inputs/cmsAttributeDateTime";
import CmsAttributeTime from "@/components/cms/cmsAttributes/inputs/cmsAttributeTime";
import CmsAttributeEnum from "@/components/cms/cmsAttributes/inputs/cmsAttributeEnum";
import { AttributeValueEntry } from "@/components/cms/cmsAttributes/cmsAttributes";
import { PbDropdownItem } from "@/components/input/pbDropdown/pbDropdown";
import { useRouter } from "next/router";
import { isLocaleDefaultLocale } from "@/utils/localizationUtil";
import ModalInputWrapper from "@/components/util/modalInputWrapper";
import CmsAttributeMedia from "@/components/cms/cmsAttributes/inputs/cmsAttributeMedia";
import { MediaManagerFilterOption } from "@/components/cms/cmsMediaManager/cmsMediaManagerFilter";
import {
  MEDIA_TYPE_AUDIO,
  MEDIA_TYPE_DOCUMENT,
  MEDIA_TYPE_IMG,
  MEDIA_TYPE_SVG,
  MEDIA_TYPE_VIDEO,
} from "@/utils/constants";
import CmsAttributeRelation from "@/components/cms/cmsAttributes/inputs/cmsAttributeRelation";
import useCmsTranslation from "@/hooks/useCmsTranslation";

interface CmsAttributeRendererProps {
  entry: AttributeValueEntry;
  idPrefix?: string;
  localized: boolean;
  onChange: (name: string, value: any) => void;
}

export default function CmsAttributeRenderer(
  props: Readonly<CmsAttributeRendererProps>
) {
  const router = useRouter();
  const tCms = useCmsTranslation();
  const isNonLocalizedDisabled =
    !props.localized && !isLocaleDefaultLocale(router.locale!);

  function renderAttribute(type: string) {
    switch (type) {
      case "string":
        return (
          <CmsAttributeString
            name={props.entry.name}
            value={props.entry.value}
            onChange={props.onChange}
            isNonLocalizedDisabled={isNonLocalizedDisabled}
          />
        );
      case "text":
        return (
          <CmsAttributeText
            name={props.entry.name}
            value={props.entry.value}
            isNonLocalizedDisabled={isNonLocalizedDisabled}
            onChange={props.onChange}
          />
        );
      case "richtext":
        return (
          <CmsAttributeRichText
            name={props.entry.name}
            value={props.entry.value}
            onChange={props.onChange}
          />
        );
      case "boolean":
        return (
          <CmsAttributeBoolean
            name={props.entry.name}
            value={props.entry.value}
            isNonLocalizedDisabled={isNonLocalizedDisabled}
            onChange={props.onChange}
          />
        );
      case "integer":
        return (
          <CmsAttributeInteger
            name={props.entry.name}
            value={props.entry.value}
            onChange={props.onChange}
          />
        );
      case "date":
        return (
          <CmsAttributeDate
            name={props.entry.name}
            value={props.entry.value}
            isNonLocalizedDisabled={isNonLocalizedDisabled}
            onChange={props.onChange}
          />
        );
      case "datetime":
        return (
          <CmsAttributeDateTime
            name={props.entry.name}
            value={props.entry.value}
            isNonLocalizedDisabled={isNonLocalizedDisabled}
            onChange={props.onChange}
          />
        );
      case "time":
        return (
          <CmsAttributeTime
            name={props.entry.name}
            value={props.entry.value}
            isNonLocalizedDisabled={isNonLocalizedDisabled}
            onChange={props.onChange}
          />
        );
      case "enumeration":
        return (
          <CmsAttributeEnum
            name={props.entry.name}
            value={props.entry.value ?? props.entry.attributes.default}
            options={props.entry.attributes.enum.map(
              (enumValue: string) =>
                ({
                  name: props.entry.name + ".value." + enumValue,
                  value: enumValue,
                } as PbDropdownItem)
            )}
            onChange={props.onChange}
            isNonLocalizedDisabled={isNonLocalizedDisabled}
          />
        );
      case "media":
        const strapiMediaTypes = (): MediaManagerFilterOption[] | undefined => {
          const allowedTypes = props.entry.attributes["allowedTypes"];
          if (!allowedTypes) {
            return undefined;
          }
          const typeMapping: Record<string, MediaManagerFilterOption[]> = {
            images: [MEDIA_TYPE_IMG, MEDIA_TYPE_SVG],
            files: [MEDIA_TYPE_DOCUMENT],
            videos: [MEDIA_TYPE_VIDEO],
            audios: [MEDIA_TYPE_AUDIO],
          };
          return allowedTypes.flatMap(
            (type: string) => typeMapping[type] || []
          );
        };

        return (
          <CmsAttributeMedia
            name={props.entry.name}
            value={props.entry.value}
            allowedTypes={strapiMediaTypes()}
            onChange={props.onChange}
            isNonLocalizedDisabled={isNonLocalizedDisabled}
          />
        );
      case "relation":
        if (props.entry.attributes.target === "plugin::pabu.pbcategory") {
          return (
            <CmsAttributeRelation
              name={props.entry.name}
              value={props.entry.value}
              relation={props.entry.attributes.relation}
              target={props.entry.attributes.target}
              optionLabelPath={`localizedName.${router.locale!}`}
              isNonLocalizedDisabled={isNonLocalizedDisabled}
              onChange={props.onChange}
            />
          );
        }
        return (
          <CmsAttributeRelation
            name={props.entry.name}
            value={props.entry.value}
            relation={props.entry.attributes.relation}
            target={props.entry.attributes.target}
            isNonLocalizedDisabled={isNonLocalizedDisabled}
            onChange={props.onChange}
          />
        );
      default:
        return (
          <>
            <p>
              Type <b>{props.entry.attributes.type}</b> not supported
            </p>
            <pre key={props.entry.name}>
              {JSON.stringify(props.entry, null, 2)}
            </pre>
          </>
        );
    }
  }

  return (
    <>
      <ModalInputWrapper
        label={tCms(`${props.idPrefix}-${props.entry.name}`)}
        description={tCms(
          `${props.idPrefix}-${props.entry.name}-description`,
          undefined,
          " "
        )}
        fullWidth={props.entry.attributes.type === "richtext"}
        nonLocalized={!props.localized}
      >
        {renderAttribute(props.entry.attributes.type)}
      </ModalInputWrapper>
    </>
  );
}
