import PbDropdown from "@/components/input/pbDropdown/pbDropdown";
import ModalInputWrapper from "@/components/util/modalInputWrapper";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import {
  MEDIA_TYPE_AUDIO,
  MEDIA_TYPE_IMG,
  MEDIA_TYPE_VIDEO,
  MEDIA_TYPE_YOUTUBE,
} from "@/utils/constants";
import { SetStateAction, useState } from "react";

export type MediaSelectorOption =
  | typeof MEDIA_TYPE_IMG
  | typeof MEDIA_TYPE_YOUTUBE
  | typeof MEDIA_TYPE_VIDEO
  | typeof MEDIA_TYPE_AUDIO;

interface CmsMediaSelectorDropdownProps {
  defaultValue: MediaSelectorOption;
  mediaOptions?: Array<MediaSelectorOption>;
  onChange: (mediaType: MediaSelectorOption) => void;
  id?: string;
}

export default function CmsMediaSelectorDropdown(
  props: CmsMediaSelectorDropdownProps
) {
  const tCms = useCmsTranslation();
  const [selectedMediaType, setSelectedMediaType] = useState(
    props.defaultValue
  );
  const defaultMediaOptions: MediaSelectorOption[] = props.mediaOptions ?? [
    MEDIA_TYPE_IMG,
    MEDIA_TYPE_YOUTUBE,
    MEDIA_TYPE_VIDEO,
    MEDIA_TYPE_AUDIO,
  ];
  const mediaDropdownItems = defaultMediaOptions.map((mediaOption) => ({
    name: tCms(mediaOption),
    value: mediaOption,
  }));

  return (
    <ModalInputWrapper
      label={tCms("mediaType")}
      description={tCms("mediaSelector-pleaseSelectType")}
    >
      <PbDropdown
        id={props.id ? `${props.id}-media-type-dropdown` : "media-type-dropdown"}
        withCmsStyle
        dropdownList={mediaDropdownItems}
        onChange={(e) => {
          props.onChange(e.target.value as MediaSelectorOption);
          setSelectedMediaType(
            e.target.value as SetStateAction<
              "image" | "youtubeVideo" | "video" | "audio"
            >
          );
        }}
        value={selectedMediaType}
        itemNameKey={"name"}
        itemValueKey={"value"}
      ></PbDropdown>
    </ModalInputWrapper>
  );
}
