import PbDateTime from "@/components/input/pbDateTime/pbDateTime";

interface CmsAttributeDateTimeProps {
  name: string;
  value: string;
  onChange: (name: string, value: string) => void;
  isNonLocalizedDisabled?: boolean;
}

export default function CmsAttributeDateTime(
  props: Readonly<CmsAttributeDateTimeProps>
) {
  return (
    <PbDateTime
      fullWidth
      withCmsStyle
      value={props.value}
      disabled={props.isNonLocalizedDisabled}
      onChange={(value: any, type: any) =>
        props.onChange(props.name, value as string)
      }
    />
  );
}
