import { ConfigModalEntry } from "@/components/cms/cmsConfigModal/cmsConfigModal";

import { ConfigModalTab } from "@/components/cms/cmsConfigModal/genericConfigTabs";
import { CFG_CONTENT_ELEMENT_SETTING_NAME } from "@/services/ceSettings/ceSettingsService";
import { cmsSettings } from "@/services/cmsSettings/cmsSettingsService";
import { deepImmutableCopy } from "@/utils/util";
import useCmsTranslation from "./useCmsTranslation";

export default function useConfigModalTabs(
  attributes: ConfigModalEntry[],
  component: string | undefined
) {
  let configModalEntries: ConfigModalEntry[] = deepImmutableCopy(attributes);

  const tCms = useCmsTranslation();

  let configModalTabs: ConfigModalTab[] = [];
  let animationModalEntries: ConfigModalEntry[] = [];

  function searchAndSplitConfigModalEntries(
    configModalEntries: ConfigModalEntry[],
    searchTerm: string
  ): {
    foundConfigModalEntries: ConfigModalEntry[];
    remainingConfigModalEntries: ConfigModalEntry[];
  } {
    const foundConfigModalEntries: ConfigModalEntry[] = [];
    const remainingConfigModalEntries: ConfigModalEntry[] = [];

    for (const configModalEntry of configModalEntries) {
      if (configModalEntry.name === searchTerm) {
        foundConfigModalEntries.push(configModalEntry);
      } else {
        remainingConfigModalEntries.push(configModalEntry);
      }
    }

    return { foundConfigModalEntries, remainingConfigModalEntries };
  }

  function moveContentElementSettingEntryToTop(
    configModalEntries: ConfigModalEntry[]
  ) {
    const index = configModalEntries.findIndex(
      (entry) => entry.name === CFG_CONTENT_ELEMENT_SETTING_NAME
    );
    if (index !== -1) {
      const [contentElementSettingEntry] = configModalEntries.splice(index, 1);
      configModalEntries.unshift(contentElementSettingEntry);
    }
  }

  for (const animationConfigEntry of configModalEntries.filter(
    (config) =>
      config.name.startsWith("cfgAnimation") ||
      config.attributes.tab === "animations"
  )) {
    const { foundConfigModalEntries, remainingConfigModalEntries } =
      searchAndSplitConfigModalEntries(
        configModalEntries,
        animationConfigEntry.name
      );
    configModalEntries = remainingConfigModalEntries;
    animationModalEntries.push(...foundConfigModalEntries);
  }

  if (animationModalEntries.length > 0) {
    configModalTabs.unshift({
      label: tCms("configModalTab-animations"),
      tabName: "animations",
      value: "1",
      configModalEntries: animationModalEntries,
    });
  }

  // find and add tabs defined by schema
  for (const customTabConfigEntry of configModalEntries.filter(
    (config) => config.attributes.tab
  )) {
    const customTabName: string = customTabConfigEntry.attributes.tab;
    const { foundConfigModalEntries, remainingConfigModalEntries } =
      searchAndSplitConfigModalEntries(
        configModalEntries,
        customTabConfigEntry.name
      );
    configModalEntries = remainingConfigModalEntries;

    const existingTab = configModalTabs.find(
      (tab) => tab.tabName === customTabName
    );
    if (!existingTab) {
      configModalTabs.push({
        label: tCms(customTabName),
        tabName: customTabName,
        value: (configModalTabs.length + 1).toString(),
        configModalEntries: [...foundConfigModalEntries],
      });
    } else {
      existingTab.configModalEntries.push(...foundConfigModalEntries);
    }
  }

  moveContentElementSettingEntryToTop(configModalEntries);
  configModalTabs.unshift({
    label: tCms("options"),
    tabName: "options",
    value: "0",
    configModalEntries: configModalEntries,
  });

  const filterConfigModalFieldsFromConfigModalTabs = (
    configModalTabs: ConfigModalTab[],
    fieldNames: string | string[]
  ): ConfigModalTab[] => {
    if (!Array.isArray) {
      fieldNames = [fieldNames as string];
    }
    for (const configModalTab of configModalTabs) {
      if (configModalTab.configModalEntries) {
        configModalTab.configModalEntries =
          configModalTab.configModalEntries.filter((cfgModalEntry, index) => {
            return !fieldNames.includes(cfgModalEntry.name);
          });
      }
    }
    return configModalTabs;
  };

  return { configModalTabs, filterConfigModalFieldsFromConfigModalTabs };
}
