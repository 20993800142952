import CmsImage from "@/components/cms/cmsImage/cmsImage";
import CmsMediaManagerSelector from "@/components/cms/cmsMediaManager/cmsMediaManagerSelector";
import ModalInputWrapper from "@/components/util/modalInputWrapper";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import { StrapiUploadFile } from "@/types/strapi";
import { MEDIA_TYPE_IMG, MEDIA_TYPE_SVG } from "@/utils/constants";
import { useState } from "react";

interface CmsMediaSelectorImageProps {
  maxHeight?: number;
  img?: StrapiUploadFile;
  onChange: (onChangeImageValues: OnChangeImageValues) => void;
  id?: string;
}

export interface OnChangeImageValues {
  img: StrapiUploadFile | null;
}

export default function CmsMediaSelectorImage(
  props: CmsMediaSelectorImageProps
) {
  const tCms = useCmsTranslation();
  const [file, setFile] = useState<StrapiUploadFile | undefined>(props.img);
  return (
    <ModalInputWrapper
      label={tCms("image")}
      description={tCms("mediaSelector-pleaseSelectImage")}
    >
      <CmsMediaManagerSelector
        id={props.id && `${props.id}-img-selector`}
        mimeTypes={[MEDIA_TYPE_IMG, MEDIA_TYPE_SVG]}
        onConfirm={(file) => {
          setFile(file);
          props.onChange &&
            props.onChange({
              img: file,
            });
        }}
      >
        {file && (
          <CmsImage
            responsive={{
              mobile: props.maxHeight,
              tablet: props.maxHeight,
              desktop: props.maxHeight,
              wqhd: props.maxHeight,
            }}
            image={file}
            format="thumbnail"
          ></CmsImage>
        )}
      </CmsMediaManagerSelector>
    </ModalInputWrapper>
  );
}
